import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.service.type === 'AMOUNT')
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createTextVNode(" $" + _toDisplayString(_ctx.service.value), 1)
      ], 64))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createTextVNode(_toDisplayString(_ctx.service.value) + "% ", 1)
      ], 64))
}