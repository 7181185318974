
import {defineComponent, ref, watch} from 'vue'
import *as Yup from 'yup';
import BaseForm from "@/components/base/form/BaseForm.vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import Froala from "@/components/base/editor/Froala.vue";
import ServiceService from "@/core/services/ServiceService";
import {Field} from "vee-validate";

export default defineComponent({
  name: "FormService",
  components: {Froala, BaseRadio, FormErrorMessage, BaseForm, Field},
  props: {
    id: {},
    service: {type: Object}
  },
  emits: ['saved'],
  setup(props) {
    const model = ref(props.service);
    watch(() => props.service, cb => {
      model.value = cb;
    })
    const submitting = ref(false);
    const validate = Yup.object().shape({
      name: Yup.string().required().label("Name").max(255),
    })
    return {
      validate,
      submitting,
      model
    }
  },
  methods: {
    submit() {
      this.submitting = true;
      if (this.id) {
        ServiceService.update(this.id, this.model).then(res => {
          this.$emit('saved', res);
        }).finally(() => {
          this.submitting = false
        })
      } else {
        ServiceService.create(this.model).then(res => {
          this.$emit('saved', res);
        }).finally(() => {
          this.submitting = false
        })
      }
    }
  }
})
