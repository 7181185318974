import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "menu-item px-3" }
const _hoisted_2 = { class: "card card-flush" }
const _hoisted_3 = { class: "card-body pt-0" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "menu-item px-3" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormService = _resolveComponent("FormService")!
  const _component_base_modal = _resolveComponent("base-modal")!
  const _component_QuickAction = _resolveComponent("QuickAction")!
  const _component_ServiceValue = _resolveComponent("ServiceValue")!
  const _component_DateTimeFormat = _resolveComponent("DateTimeFormat")!
  const _component_KtDatatable = _resolveComponent("KtDatatable")!
  const _component_Entities = _resolveComponent("Entities")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_base_modal, {
      id: "formService",
      name: _ctx.state.modalName,
      ref: "formServiceRef"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_FormService, {
          id: _ctx.state.id,
          service: _ctx.state.service,
          onSaved: _ctx.onSaved
        }, null, 8, ["id", "service", "onSaved"])
      ]),
      _: 1
    }, 8, ["name"]),
    _createVNode(_component_Entities, {
      "enable-filter": false,
      "view-card": false,
      "display-view": false,
      items: _ctx.page.data.total,
      label: "Service",
      "view-table": true
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_QuickAction, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("a", {
                href: "javascript:void(0);",
                class: "menu-link px-3",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onAdd && _ctx.onAdd(...args)))
              }, "Add Service")
            ])
          ]),
          _: 1
        })
      ]),
      table: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_KtDatatable, {
              "table-data": _ctx.page.data.data,
              "table-header": _ctx.tableHeader,
              "enable-items-per-page-dropdown": true,
              "current-page": _ctx.filter.paginationPage,
              "rows-per-page": _ctx.filter.paginationSize,
              onItemsPerPageChange: _ctx.handleUpdateSize,
              onCurrentChange: _ctx.handleUpdatePage,
              total: _ctx.page.data.total
            }, {
              "cell-code": _withCtx(({ row: row }) => [
                _createElementVNode("span", {
                  class: "link-primary cursor-pointer hover",
                  onClick: ($event: any) => (_ctx.loadData(row.id,'SERVICE'))
                }, _toDisplayString(row.code), 9, _hoisted_4)
              ]),
              "cell-name": _withCtx(({ row: row }) => [
                _createElementVNode("span", {
                  class: "link-primary cursor-pointer hover",
                  onClick: ($event: any) => (_ctx.loadData(row.id,'SERVICE'))
                }, _toDisplayString(row.name), 9, _hoisted_5)
              ]),
              "cell-type": _withCtx(({ row: row }) => [
                _createTextVNode(_toDisplayString(row.type === 'AMOUNT' ? 'Amount' : 'Percent'), 1)
              ]),
              "cell-value": _withCtx(({ row: row }) => [
                _createVNode(_component_ServiceValue, { service: row }, null, 8, ["service"])
              ]),
              "cell-created": _withCtx(({ row: row }) => [
                _createVNode(_component_DateTimeFormat, {
                  date: row.dateCreated
                }, null, 8, ["date"])
              ]),
              "cell-actions": _withCtx(({ row: view }) => [
                _createVNode(_component_QuickAction, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("a", {
                        href: "javascript:void(0);",
                        class: "menu-link px-3",
                        onClick: ($event: any) => (_ctx.onEdit(view))
                      }, "Edit", 8, _hoisted_7),
                      _createElementVNode("a", {
                        href: "javascript:void(0);",
                        class: "menu-link px-3 text-danger",
                        onClick: ($event: any) => (_ctx.onDelete(view.id))
                      }, "Delete", 8, _hoisted_8)
                    ])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            }, 8, ["table-data", "table-header", "current-page", "rows-per-page", "onItemsPerPageChange", "onCurrentChange", "total"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["items"])
  ], 64))
}