
import {computed, defineComponent, ref} from 'vue'
import {LoadFilterObjects, LoadPanel} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import store from "@/store";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import Entities from "@/components/base/common/Entities.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import FormService from "@/views/service/FormService.vue";
import Swal from "sweetalert2";
import ServiceService from "@/core/services/ServiceService";
import ServiceValue from "@/views/service/ServiceValue.vue";

export default defineComponent({
  name: "List",
  components: {ServiceValue, FormService, BaseModal, DateTimeFormat, QuickAction, Entities, KtDatatable},
  props: {
    companyId: {},
  },
  setup(props) {
    const state = ref<any>({
      id: '',
      service: {code: '', name: '', type: 'AMOUNT', value: 0, description: '', companyIdRequest: ''},
      modalName: 'New Service'
    })
    const objectFilter = ref({companyId: props.companyId})
    const page = computed(() => store.state.ServiceModule.page);
    const tableHeader = ref([
      {
        name: "Code",
        key: "code",
        sortable: true,
      },
      {
        name: "Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Type",
        key: "type",
        sortable: true,
      },
      {
        name: "Value",
        key: "value",
        sortable: true,
      },
      {
        name: "Created",
        key: "created",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
        sortable: true,
      },
    ]);
    return {
      tableHeader,
      page,
      ...LoadPanel(),
      state,
      ...LoadFilterObjects(Actions.LOAD_SERVICES, objectFilter.value, []),
    }
  },
  methods: {

    onAdd() {
      const modal = this.$refs.formServiceRef as typeof BaseModal;
      modal.showBaseModal();
      this.state = {
        id: '',
        service: {code: '', name: '', type: 'AMOUNT', value: 0, description: '', companyIdRequest: this.companyId},
        modalName: 'New Service'
      }
    },
    onEdit(row) {
      this.state = {
        id: row.id,
        service: {
          code: row.code,
          name: row.name,
          type: row.type,
          value: row.value,
          description: row.description,
          companyIdRequest: this.companyId
        },
        modalName: 'Edit Service'
      }
      const modal = this.$refs.formServiceRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onSaved() {
      this.paginationLoad();
      const modal = this.$refs.formServiceRef as typeof BaseModal;
      modal.hideBaseModal();
    },
    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await ServiceService.delete(id).then(() => {
            this.paginationLoad()
          })
        }
      })
    }
  }

})
